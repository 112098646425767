import React from 'react';
import { Grid, Button } from '@material-ui/core';
import './EventWebinarContentHeader.scss';
import { myStyles } from './styles';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { DateFormatUtils } from '../../../utils/date-formatter';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import VideoTiles from '../../VideoTiles';

const EventWebinarContentHeader = ({
  heroImage,
  secondaryImg,
  dotMatrixBackgroundImage,
  heroBackgroundImage,
  resourceType,
  dateOfPublishing,
  eventsOrWebinarTitle,
  eventOrWebinarDetails,
  headerParagraphText,
  paragraphTextHeader,
  firstTitle,
  secondTitle,
  registerBtn,
  isImgOrVideo,
  video
}) => {
  const data = {dotMatrixBackgroundImage, heroBackgroundImage};
  const classes = myStyles(data);
  const { date, month, year } = DateFormatUtils.DateMonthYearFormatter(dateOfPublishing);

  return (
    <>
      <Grid container className="eventswebinar-content-header-cls">
        {/* **********************    Text-with-Image-row    ********************** */}
        <Grid item xl={1} lg={1} md={2} sm={0} xs={0}></Grid>
        <Grid item xl={11} lg={11} md={10} sm={12} xs={12}>
          <Grid container className="webinar-header-container">
            <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
              <div className="header-title-sec" locId="resourceType">
                <p className="header-date" locId="dateOfPublishing">
                  {resourceType} / {date} {month} {year}
                </p>
                <h1 locId="eventsOrWebinarTitle">{eventsOrWebinarTitle}</h1>
              </div>
            </Grid>

            <Grid item xl={7} lg={7} md={12} sm={9} xs={12}>
              <div className={clsx('webinar-header-heroimg', classes['webinar-header-heroimg'])} locId="heroImage">
               {isImgOrVideo && <img src={heroImage?.file?.url+'?fm=webp&q=100'} locId="heroImage" alt={heroImage?.description} />}
                {!isImgOrVideo && <VideoTiles
                        thumbnailClass={'video-img'}
                        thumbnailOverLay={'video-overlay'}
                        data={{videoComponent:video}}
                        />}
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* **********************   Text-row-2    ********************** */}
        <Grid container>
          <Grid item xl={1} lg={1} md={2} sm={0} xs={0}></Grid>
          <Grid item xl={4} lg={4} md={10} sm={10} xs={12}>
            <div className="eventswebinar-content-rich-txt" locId="eventOrWebinarDetails">
              <p locId="eventOrWebinarDetails">
                {eventOrWebinarDetails?.raw &&
                  documentToReactComponents(JSON.parse(eventOrWebinarDetails?.raw))}
              </p>
              {registerBtn && (<Link
                to={registerBtn?.link?.url ? registerBtn?.link?.url : '#'}
                tabindex="0"
                target={registerBtn?.link?.openInANewTab ? '_blank' : '_self'}
              >
                <Button variant="contained" className="blue-btn" tabindex="-1" locId="registerNow">
                  {registerBtn.label}
                </Button>
              </Link>)}
            </div>
          </Grid>

          <Grid container item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Grid item xl={2} lg={2} md={3} sm={2} xs={0}></Grid>
            <Grid item xl={10} lg={10} md={9} sm={10} xs={12}>
              <div className="webinar-des">
                <h3 className="title" locId="firstTitle">{firstTitle}</h3>
                <p className="para_wrap" locId="headerParagraphText">
                  {headerParagraphText?.raw &&
                    documentToReactComponents(JSON.parse(headerParagraphText?.raw))}
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid item xl={1} lg={1} md={0} sm={0} xs={0}></Grid>
        </Grid>

        {/* **********************   Text-row-3    ********************** */}
        <Grid container className="webinar-img-txt-container">
          <Grid item xl={5} lg={5} md={7} sm={9} xs={11}>
            {secondaryImg?.file?.url && (<div className={clsx('img-thumb-div', classes['webinar-img-txt-container'])} locId="secondaryImage">
              <img
                className="img-thumb"
                src={secondaryImg?.file?.url+'?fm=webp&q=100'}
                alt={secondaryImg?.description}
              />
            </div>)}
          </Grid>

          <Grid container item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Grid item xl={2} lg={2} md={3} sm={2} xs={0}></Grid>
            <Grid item xl={10} lg={10} md={9} sm={10} xs={12}>
              <div className="webinar-des">
                <h3 className="title" locId="secondTitle">{secondTitle}</h3>
                <p className="para_wrap" locId="paragraphTextHeader">
                  {paragraphTextHeader?.raw &&
                    documentToReactComponents(JSON.parse(paragraphTextHeader?.raw))}
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid item xl={1} lg={1} md={0} sm={0} xs={0}></Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default EventWebinarContentHeader;
