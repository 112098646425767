import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
import { graphql } from 'gatsby';
import EventWebinarContent from '../components/EventWebinarContent';
export default function EventWebinarContentPage({location ,pageContext, data }) {
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const [user, setUser] = useState({});
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };
  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };

  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulEventsAndWebinar?.seoEntry}
          siteurl={pageContext?.siteurl}
          bucketName={pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName={pageContext?.appData?.organizationName}
          organizationLogo={pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink={pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink={pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink={pageContext?.appData?.organizationYoutubeLink}
          location={location}
        />
        <EventWebinarContent
          data={data?.contentfulEventsAndWebinar}
          basePath={pageContext?.basePath}
          bucketname={pageContext?.bucketName}
          pastEvents={pageContext?.allEventWebinarData}
          images={data?.contentfulComponentBackgroundImages}
          eventsWebinarUrl={
            data?.contentfulCategoryTagsLandingPage?.slug
          }
          location={location}
        />
      </Layout>
    </>
  );
}

export const query = graphql`
  query eventWebinarContentPageTemplate(
    $contentful_id: String
    $locale: String
    $partnerId: String
  ) { 
    contentfulCategoryTagsLandingPage{
    entryTitle
    slug
    }
    contentfulPartnerSite(partnerId: { eq: $partnerId }) {
      navigation {
        menus {
          menuLink {
            link {
              ... on ContentfulInternalLink {
                id
                referenceToPage {
                  ... on ContentfulCustomResourceHub {
                    id
                    moreEventsWebinars {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    contentfulComponentBackgroundImages {
      smallDotMatrixBackgroundImage {
        file {
          url
        }
      }
      researchDonutBackgroundImage {
        file {
          url
        }
      }
      researchGridBridgeImage {
        file {
          url
        }
      }
      capsulesBackgroundImage {
        file {
          url
        }
      }
      downloadButtonImage {
        description
        file {
          url
        }
      }
      linkedlnIcon {
        description
        file {
          url
        }
      }
      gridBridgeImage {
        file {
          url
        }
      }
      twitterIcon {
        description
        file {
          url
        }
      }
      emailIcon {
        description
        file {
          url
        }
      }
      facebookIcon {
        description
        file {
          url
        }
      }
      sys {
        contentType {
          sys {
            type
          }
        }
      }
    }
    contentfulEventsAndWebinar(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $locale }
    ) {
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          file {
            url
          }
        }
      }
      contentful_id
      imageOrVideo
      buttonLabel
      dateOfEvent
      dateOfPublishing
      eventsOrWebinarTitle
      shortSummary {
        raw
      }
      categoryTitle
      tagsTitle
      categoryList {
        categoryName
        contentful_id
      }
      tagList {
        tagName
        contentful_id
      }
      video {
        videoId
        videoType
      }
      slug
      eventThumbnailImage {
        file {
          url
        }
        description
      }
      resourceType
      dateOfPublishing
      dropdownLabel
      eventsOrWebinarTitle
      heroImage {
        description
        file {
          url
        }
      }
      dateOfEvent
      eventOrWebinarDetails {
        raw
      }
      headerParagraphText {
        raw
      }
      image {
        file {
          url
        }
        description
      }
      paragraphTextHeader {
        raw
      }
      header1
      header2
      ctaButton {
        link {
          ... on ContentfulWebLink {
            id
            url
            openInANewTab
          }
        }
        label
      }
      region
      presentersHeading
      presenters {
        image {
          description
          file {
            url
          }
        }
        designation
        name
        areaOfExpertiseOrCountry
      }
      ourHosts {
        areaOfExpertiseOrCountry
        designation
        name
        image {
          description
          file {
            url
          }
          description
        }
      }
      ourHostsHeading
      pastEventsHeading
    }
  }
`;
