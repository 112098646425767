import React from "react";
import { Card, CardContent, CardMedia, Grid } from "@material-ui/core";
import "./Presenter.scss"
export default function Presenter({presenter}){
    return(
        <Grid className="tile-spacing">
                <Card className="our-presenter-card">
                  <CardMedia component="img" locId="presenterImage" alt={presenter?.image?.description} image={presenter?.image?.file?.url+'?fm=webp&q=100'} />
                  <CardContent>
                    <h4 locId="presenterName">{presenter?.name}</h4>
                    <p locId="presenterDesignation">
                      {presenter?.designation} <span locId="presenterExpertise">{presenter?.areaOfExpertiseOrCountry}</span>
                    </p>
                  </CardContent>
                </Card>
              </Grid>
    )
}