import React from 'react';
import Container from '@material-ui/core/Container';
import EventWebinarContentEvent from './EventWebinarContentEvent';
import EventWebinarContentHeader from './EventWebinarContentHeader';
import EventWebinarContentPresenter from './EventWebinarContentPresenter';
import "./EventWebinarContent.scss";
import { myStyles } from './styles';
import clsx from 'clsx';
export default function EventWebinarContent({ data, pastEvents, basePath, images,eventsWebinarUrl, bucketname,location }) {
  const classes = myStyles({researchDonutBackgroundImage:images?.researchDonutBackgroundImage,capsulesBackgroundImage:images?.capsulesBackgroundImage});

  return (
    <>
      <div className="events-webinarcontent-main">
        <div className={clsx("bg-donuts-eventswebinar",classes['bg-donuts-eventswebinar'])}>
        <Container maxWidth="xl" className='events-content-container-div'>
          <EventWebinarContentHeader
            resourceType={data?.resourceType}
            dateOfPublishing={data?.dateOfPublishing}
            eventsOrWebinarTitle={data?.eventsOrWebinarTitle}
            dotMatrixBackgroundImage={images?.smallDotMatrixBackgroundImage}
            isImgOrVideo={data?.imageOrVideo}
            heroImage={data?.heroImage}
            heroBackgroundImage={images?.gridBridgeImage}
            eventOrWebinarDetails={data?.eventOrWebinarDetails}
            headerParagraphText={data?.headerParagraphText}
            secondaryImg={data?.image}
            paragraphTextHeader={data?.paragraphTextHeader}
            firstTitle={data?.header1}
            secondTitle={data?.header2}
            registerBtn={data?.ctaButton}
            video={data?.video}
          />
           </Container>
          <EventWebinarContentPresenter
            data={{
              ourHostsHeading: data?.ourHostsHeading,
              presentersHeading: data?.presentersHeading,
              presenters: data?.presenters,
              ourHosts: data?.ourHosts,
              capsuleImage:images?.capsulesBackgroundImage
            }}
          />
           <Container maxWidth="xl" className='events-content-container-div'>
          <EventWebinarContentEvent
            data={data}
            pastEvents={pastEvents}
            basePath={basePath}
            landingUrl={eventsWebinarUrl}
            images={images}
            bucketname={bucketname}
            location={location}
          />
          </Container>
        </div>
      </div>
    </>
  );
}
