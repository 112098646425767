class EventWordCount {
    static generateTotalContent(element,TotalContent) {
        element.forEach((el) => {
          el?.content.forEach((childElement) => {
            TotalContent = TotalContent + ' ' + childElement?.value?.trim();
          });
        });
        return TotalContent;
      }
    static WordCount(data) {
        let TotalContent = '';
         if (data?.eventOrWebinarDetails?.raw) {
            TotalContent = this.generateTotalContent(JSON.parse(data?.eventOrWebinarDetails?.raw).content);
          }
          if (data?.headerParagraphText?.raw) {
            TotalContent = this.generateTotalContent(JSON.parse(data?.headerParagraphText?.raw).content);
          }
          if (data?.paragraphTextHeader?.raw) {
            TotalContent = this.generateTotalContent(JSON.parse(data?.paragraphTextHeader?.raw).content);
          }
         return TotalContent;
    } 
}
export { EventWordCount };