import React from 'react';
import { Grid } from '@material-ui/core';
import './OurHostsTile.scss';
import Presenter from '../Presenter';

export default function EventwebinarcontentOurHostsTile({ data }) {
  const { ourHosts } = data;
  return (
    <>
      {ourHosts?.map((host, index) => {
        return (
          <>
            <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
              <Presenter presenter={host} />
            </Grid>
            {(index === 1 || index === 3 || index === 5 || index === 7 || index === 9) && (
              <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                &nbsp;
              </Grid>
            )}
          </>
        );
      })}
    </>
  );
}