import React from "react";
import './EventWebinarContentPresenter.scss'
import { Grid } from '@material-ui/core';
import PresentersTile from './PresentersTile';
import OurHostsTile from './OurHostsTile';
import { myStyles } from '../styles';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';

export default function EventWebinarContentPresenter({data}){
  const {ourHostsHeading, presentersHeading, presenters, ourHosts,capsuleImage} = data;
  const classes = myStyles(capsuleImage);
    return(
        <>
    {/* presenters component start */}
        {presenters && <Container maxWidth="xl" className='events-content-container-div'>
          <Grid container className='events-webinar-presenters-cls'>
            {/* **********************    Text-with-Image-row    ********************** */}
            <Grid item xl={1} lg={1} md={1} sm={0} xs={0}></Grid>

            <Grid item xl={10} lg={10} md={7} sm={12} xs={12}>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <h2 className='presenters-title title-section' locId="presentersHeading">{presentersHeading}</h2>
                </Grid>
                <PresentersTile data={{ presenters }} />
              </Grid>
            </Grid>
            <Grid item xl={1} lg={1} md={4} sm={12} xs={12}></Grid>

          </Grid>
        </Container>}
    {/* Our host component start   */}
        {ourHosts && <div className={clsx("capsules-bg-left", classes['capsules-bg-left'])}>
          <Container maxWidth="xl" className='events-content-container-div'>
            <Grid container className='events-webinar-host-cls'>
              {/* **********************    Text-with-Image-row    ********************** */}
              <Grid item xl={1} lg={1} md={1} sm={0} xs={0}></Grid>

              <Grid item xl={10} lg={10} md={7} sm={12} xs={12}>
                <Grid container direction="row-reverse">
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h2 className='our-host-title title-section' locId="ourHostsHeading">{ourHostsHeading}</h2>
                  </Grid>
                  <OurHostsTile data={{ ourHosts }} />
                </Grid>
              </Grid>
              <Grid item xl={1} lg={1} md={4} sm={12} xs={12}></Grid>

            </Grid>
          </Container>
        </div>}
    </>
    )
}
