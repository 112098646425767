import React from 'react';
import { Grid } from '@material-ui/core';
import './EventwebinarcontentPastEventsTile.scss';
import CategorySocialMedia from '../../CategorySocialMedia';
import EventsWebinarTile from '../../EventsWebinarTile';
import { myStyles } from './style';
import clsx from 'clsx';
import wordCountProvider from '../../../components/word-count/word-count';
import { EventWordCount } from './EventWordCount';

export default function EventWebinarContentEvent({
  data,
  pastEvents,
  basePath,
  bucketname,
  images,
  landingUrl,
  location
}) {
  const numberOfTilesInEvents = process.env.GATSBY_NUMBER_OF_TILE_FOR_EVENTS_IN_PAST_EVENTS;
  const classes = myStyles(images);
  const TotalContent = EventWordCount.WordCount(data);
  const wordCount = wordCountProvider(TotalContent);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const pastEventsWebinarList = pastEvents.filter(
    (item) => item.contentful_id != data.contentful_id
  );
  const filteredEventsWebinarList = pastEventsWebinarList
    ?.map((events) => {
      const dateinTimestamp = new Date(events.dateOfPublishing).getTime();
      const month = new Date(Number(dateinTimestamp)).getMonth();
      const monthString = months[month];
      const formatedDateString =
        monthString + ' ' + new Date(Number(dateinTimestamp)).getFullYear();
      return { ...events, publishingDate: formatedDateString, dateinTimestamp: dateinTimestamp };
    })
    .filter((pastEventsOrWebinar) => {
      return pastEventsOrWebinar.dropdownLabel == data?.dropdownLabel;
    });
  function comparePublishingDate(a, b) {
    return b.dateinTimestamp - a.dateinTimestamp;
  }
  filteredEventsWebinarList.sort(comparePublishingDate);
  const colsList = [...filteredEventsWebinarList].splice(0, numberOfTilesInEvents);
  return (
    <>
      {colsList.length > 0 ? (
        <Grid container className={clsx('event-webinar-past-event-cls', classes['bg-event-cls'])}>
          <Grid item xl={1} lg={1} md={1}>
            &nbsp;
          </Grid>
          <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
            <Grid container className="past-events-container">
              {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} locId="pastEventsHeading"> */}
              <h2 className="event-title title-section">{data?.pastEventsHeading}</h2>
              {/* </Grid> */}
              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="research-card-container"
              >
               {colsList.map(cl=><EventsWebinarTile data={cl} basePath={basePath} />) }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={1} lg={1} md={1}>
            &nbsp;
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      {/* **********************Category and Socialmedia******************** */}
      <CategorySocialMedia
        data={data}
        path={data?.slug}
        landingUrl={landingUrl}
        type={process.env.GATSBY_CARTUS_EVENT}
        bucketname={bucketname}
        basePath={basePath}
        images={images}
        wordCount={wordCount}
        title={data?.eventsOrWebinarTitle}
        location={location}
      />
    </>
  );
}
