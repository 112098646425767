import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'webinar-header-heroimg': (data) => ({
    '&::before': {
      background: `url(${data?.heroBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    },
    '&::after': {
      background: `url(${data?.dotMatrixBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    },
  }),
  'webinar-img-txt-container': (data) => ({
    '&::after': {
      background: `url(${data?.dotMatrixBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    },
  }),
}));
