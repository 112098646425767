import React from 'react';
import { Grid, Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import './PresentersTile.scss';
import Presenter from '../Presenter';

export default function PresentersTile({ data }) {
  const { presenters } = data;
  return (
    <>
      {presenters?.map((presenter, index) => {
        return (
          <>
            <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
              <Presenter presenter={presenter} />
            </Grid>
            {(index === 1 || index === 3 || index === 5 || index === 7 || index === 9) && (
              <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
                &nbsp;
              </Grid>
            )}
          </>
        );
      })}
    </>
  );
}
